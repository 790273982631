import type { AdminVmLimits } from "@warrenio/api-spec/spec.oats.gen";
import { type ReactNode, useState } from "react";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { AInput } from "../form/Fields.tsx";
import { updateVmLimitsMutation } from "./settingsQuery.ts";

interface VmLimitsModalProps {
    value: number;
    title: string;
    limits: AdminVmLimits;
    type: string;
}

interface VmLimitsButtonProps extends VmLimitsModalProps {
    button: ReactNode;
}

export function VmLimitsButton(props: VmLimitsButtonProps) {
    return (
        <WModal button={props.button}>
            <VmLimitsModalContent {...props} />
        </WModal>
    );
}

function VmLimitsModalContent(props: VmLimitsModalProps) {
    const { value, title, type, limits } = props;
    const updateMutation = useStandardMutation(updateVmLimitsMutation);
    const [current, setCurrent] = useState<number | string>(value);

    async function onSubmit() {
        await updateMutation.mutateAsync({
            body: { ...limits, [type]: Number(current) },
        });
    }

    return (
        <WModalContent title={`Update ${title}`} label="Update" modalAction={async () => await onSubmit()}>
            <WTextField isRequired autoFocus className="max-w-24rem" wide label={title} onChange={setCurrent}>
                <AInput value={current} />
            </WTextField>
        </WModalContent>
    );
}
