import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type {
    AdminVmLimits,
    DefaultActivePostPaymentLevel,
    FirstAccountCredit,
    GeneralSettings,
    LowBalanceNoticeThresholdBody,
    MinTopupAmount,
    RestrictionLevelClearTopupThreshold,
    RestrictionLevelThresholds,
} from "@warrenio/api-spec/spec.oats.gen";
import type { ApiClient } from "../../modules/api/apiClient";
import { getResponseData } from "../../modules/api/apiClient";
import { jsonEncodedBody } from "../../utils/fetchClient.ts";
import { adminMutation } from "../../utils/query/adminMutation.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";

export const queryKeyPayment: QueryKey = ["config/payment"];
export const queryKeyLimits: QueryKey = ["config/compute/limits/vm"];

export function getPaymentQuery(client: ApiClient) {
    return queryOptions({
        queryKey: queryKeyPayment,
        queryFn: async ({ signal }): Promise<GeneralSettings> => {
            return getResponseData(await client.GET("/config/payment", { signal }));
        },
    });
}

export function getVmLimitsQuery(client: ApiClient) {
    return queryOptions({
        queryKey: queryKeyLimits,
        queryFn: async ({ signal }): Promise<AdminVmLimits> => {
            return getResponseData(await client.GET("/config/compute/limits/vm", { signal }));
        },
    });
}

export const configPaymentQueryAtom = atomFromStandardQueryOptions(getPaymentQuery);
export const configVmLimitsQueryAtom = atomFromStandardQueryOptions(getVmLimitsQuery);

//#region Mutations

export const updateFirstAccountCreditMutation = adminMutation((apiClient) => ({
    entity: "general settings",
    operation: "update",
    requestFn: async ({ body }: { body: FirstAccountCredit }) =>
        await apiClient.PUT("/config/payment/first_account_credit", {
            ...jsonEncodedBody,
            body,
        }),
    invalidateQueryKey: queryKeyPayment,
}));

export const updateMinTopupAmountMutation = adminMutation((apiClient) => ({
    entity: "general settings",
    operation: "update",
    requestFn: async ({ body }: { body: MinTopupAmount }) =>
        await apiClient.PUT("/config/payment/min_topup_amount", {
            ...jsonEncodedBody,
            body,
        }),
    invalidateQueryKey: queryKeyPayment,
}));

export const updateRestrictionLevelClearTopupThresholdMutation = adminMutation((apiClient) => ({
    entity: "general settings",
    operation: "update",
    requestFn: async ({ body }: { body: RestrictionLevelClearTopupThreshold }) =>
        await apiClient.PUT("/config/payment/restriction_level_clear_topup_threshold", {
            ...jsonEncodedBody,
            body,
        }),
    invalidateQueryKey: queryKeyPayment,
}));

export const updateDefaultActivePostPaymentLevelMutation = adminMutation((apiClient) => ({
    entity: "general settings",
    operation: "update",
    requestFn: async ({ body }: { body: DefaultActivePostPaymentLevel }) =>
        await apiClient.PUT("/config/payment/default_active_post_payment_level", {
            ...jsonEncodedBody,
            body,
        }),
    invalidateQueryKey: queryKeyPayment,
}));

export const updateRestrictionLevelThresholdsMutation = adminMutation((apiClient) => ({
    entity: "general settings",
    operation: "update",
    requestFn: async ({ body }: { body: RestrictionLevelThresholds }) =>
        await apiClient.PUT("/config/payment/restriction_level_thresholds", {
            ...jsonEncodedBody,
            body,
        }),
    invalidateQueryKey: queryKeyPayment,
}));

export const updateLowBalanceNoticeThresholdMutation = adminMutation((apiClient) => ({
    entity: "general settings",
    operation: "update",
    requestFn: async ({ body }: { body: LowBalanceNoticeThresholdBody }) =>
        await apiClient.PUT("/config/payment/low_balance_notice_threshold", {
            ...jsonEncodedBody,
            body,
        }),
    invalidateQueryKey: queryKeyPayment,
}));

export const updateVmLimitsMutation = adminMutation((apiClient) => ({
    entity: "general settings",
    operation: "update",
    requestFn: async ({ body }: { body: AdminVmLimits }) =>
        await apiClient.PUT("/config/compute/limits/vm", {
            ...jsonEncodedBody,
            body,
        }),
    invalidateQueryKey: queryKeyLimits,
}));
//#endregion Mutations
